import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { AgentAvailableComponentPurl } from 'components/PurlBannerAgentInfo';
import Text from 'components/Text';

import { formatPhoneNumberWithBrackets } from 'utilities/formatPhoneNumber';
import { QUICK_PROFILE_ROUTE } from 'utilities/routes';

import styles from './styles.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEmail, faPhone } from '@awesome.me/kit-cc709582e6/icons/kit/custom';

export const PurlBannerContactInfo = ({ purlAgentData, isMobile }) => {
    const history = useHistory();
    const {
        agentProfessionalInfoResponse,
        isMedicareAgent,
        isPurlToggledOn,
        virtualNumber
    } = purlAgentData || {};
    const { agentOfficeLocation: office } = agentProfessionalInfoResponse || {};
    const { city = '', state = '' } = office || {};
    let phoneNumber = virtualNumber?.substring(2);

    return (
        <div className={styles.purlAgentContactContainer}>
            {isMobile && (
                <>
                    <Text className={styles.purlAgentSubText}>
                        {purlAgentData?.agencyName && (
                            <Text>{purlAgentData?.agencyName}</Text>
                        )}
                        {(city || state) && (
                            <>
                                <Text
                                    className={
                                        purlAgentData?.agencyName &&
                                        styles.agentAddress
                                    }
                                    id="agentAddress"
                                >
                                    {[city, state].filter(Boolean).join(', ')}
                                </Text>
                            </>
                        )}
                    </Text>
                </>
            )}
            {!isMobile && (
                <AgentAvailableComponentPurl
                    isPurlToggledOn={isPurlToggledOn}
                />
            )}
            <a
                className={styles.link}
                href={`mailto:${purlAgentData.email}`}
                rel="noreferrer"
                id="emailLink"
            >
                <div className={styles.contactInfoItem}>
                    <div id="email">{purlAgentData.email} </div>
                    <div>
                        <FontAwesomeIcon
                            className={styles.envelopeIcon}
                            icon={faEmail}
                            id="emailIcon"
                            color="#fff"
                            size="xl"
                        />
                    </div>
                </div>
            </a>
            <a
                className={styles.link}
                href={`tel:${phoneNumber}`}
                rel="noreferrer"
                id="phoneNumberLink"
            >
                <div className={styles.contactInfoItem}>
                    <div id="phoneNumber">
                        {formatPhoneNumberWithBrackets(phoneNumber)}{' '}
                    </div>
                    <div>
                        <FontAwesomeIcon
                            className={styles.phoneIcon}
                            icon={faPhone}
                            id="phoneIcon"
                            color="#fff"
                            size="xl"
                        />
                    </div>
                </div>
            </a>
            {window.location.pathname !== QUICK_PROFILE_ROUTE &&
                isMedicareAgent && (
                    <div className={styles.getSyncContainer}>
                        <Button
                            label="Get Synced"
                            className={styles.getSyncBtn}
                            onClick={() => history.push(QUICK_PROFILE_ROUTE)}
                            id={'syncButton'}
                        />
                    </div>
                )}
        </div>
    );
};
