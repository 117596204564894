import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfile } from 'services/clientServices/slice';
import { useUpdateMedicareAccountData } from 'api/form';

import { useAuth0 } from '@auth0/auth0-react';

import Button from 'components/Button';
import DatePickerWithLabel from 'components/DatePickerWithLabel';
import FeedbackCard from 'components/FeedbackCard';
import FirstNameTextField from 'components/FirstNameTextField';
import LastNameTextField from 'components/LastNameTextField';
import MedicareNumberTextField from 'components/MedicareNumberTextField';
import PageSpinner from 'components/PageSpinner';
import Text from 'components/Text';
import TrustedFormScript from 'components/TrustedFormScript';

import { useToDo } from 'pages/TodoItems/useToDo';

import { formatMCIN } from 'utilities/maskMedicareId';
import { PROFILE_ROUTE } from 'utilities/routes';
import { validateMedicalBeneficiaryId } from 'utilities/validate';

import styles from './styles.module.scss';

const ManageMedicareForm = forwardRef(
    (
        {
            details,
            onCancel,
            updateDetails,
            from = '',
            datePickerLabelClass,
            noPlaceholder = false,
            medicareNumberPlaceholderText,
            datePlaceHolder,
            footerButtonDisabled,
            cardHeaderClass,
            footerBlock = styles.footerBlock,
            firstNameVal = e => {},
            lastNameVal = e => {},
            medicareIdNumberVal = e => {},
            partBVal = e => {},
            partAVal = e => {}
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const userProfile = useSelector(state => state.userProfile.userProfile);
        const [loading, setLoading] = useState(false);
        const [message, setMessage] = useState(false);
        const [isDisable, setIsDisable] = useState(false);
        const [partA, setPartA] = useState('');
        const [partB, setPartB] = useState('');
        const { loginWithRedirect } = useAuth0();

        const { updateMedicareAccountData } = useUpdateMedicareAccountData();

        const { toDoUpdate } = useToDo();

        const {
            firstName = '',
            lastName = '',
            medicareIdNumber = ''
        } = details;

        useEffect(() => {
            if (details?.medicarePartADate)
                setPartA(details?.medicarePartADate);
            if (details?.medicarePartBDate)
                setPartB(details?.medicarePartBDate);
        }, [details]);

        const isProfilePage = window.location.pathname === PROFILE_ROUTE;

        const {
            register,
            handleSubmit,
            formState: { errors, isDirty },
            reset,
            setError,
            watch,
            setValue,
            clearErrors
        } = useForm({
            defaultValues: {
                firstName,
                lastName,
                medicareIdNumber:
                    medicareIdNumber && formatMCIN(medicareIdNumber)
            }
        });

        const watchMCIN = watch('medicareIdNumber');
        const watchAll = watch();

        useEffect(() => {
            const mciInput = document.getElementById('medicareIdNumber');
            let start = mciInput.selectionStart;
            watchMCIN && setValue('medicareIdNumber', formatMCIN(watchMCIN));
            if (mciInput && watchMCIN?.length !== start)
                mciInput.setSelectionRange(start, start);
        }, [watchMCIN, setValue]);

        const isValidDate = d => d instanceof Date && !isNaN(d);
        const isValid = () => {
            if (watchAll.firstName?.trim() === '') return false;
            if (watchAll.lastName?.trim() === '') return false;
            if (watchAll.medicareIdNumber?.trim() === '') return false;
            else if (
                watchAll.medicareIdNumber?.trim().length === 13 &&
                !validateMedicalBeneficiaryId(watchAll.medicareIdNumber)
            ) {
                setError('medicareIdNumber', { type: 'pattern' });
                return false;
            }

            if (!isValidDate(partA)) return false;

            return true;
        };

        useEffect(() => {
            const state = isValid();
            firstNameVal(watchAll.firstName);
            lastNameVal(watchAll.lastName);
            medicareIdNumberVal(watchAll.medicareIdNumber);
            setIsDisable(!state);
            footerButtonDisabled && footerButtonDisabled(!state);
             
        }, [watchAll, partA, validateMedicalBeneficiaryId, setIsDisable]);

        useEffect(() => {
            const defaultValue = {
                firstName,
                lastName,
                medicareIdNumber
            };
            reset(defaultValue);
        }, [firstName, lastName, medicareIdNumber, reset]);

        const updateProfile = async data => {
            setLoading(true);
            // const state = validateMedicalBeneficiaryId(data?.medicareIdNumber);
            // if (!state) {
            //     setLoading(false);
            //     setError('medicareIdNumber', { type: 'pattern' });
            //     return;
            // }
            // if (isDisable) {
            //     setLoading(false);
            //     return false;
            // }
            const payload = {
                mbiFirstName: data.firstName,
                mbiLastName: data.lastName,
                mbi: data?.medicareIdNumber.replace(/-/g, ''),
                partAEffectiveDate: null,
                partBEffectiveDate: null
            };

            if (partA) payload.partAEffectiveDate = partA.toISOString();

            if (partB && isValidDate(partB))
                payload.partBEffectiveDate = partB.toISOString();

            const medicareAccountData = {
                ...userProfile,
                ...payload
            };

            const response = await updateMedicareAccountData(
                medicareAccountData,
                true
            );

            if (response.status >= 200 && response.status < 300) {
                setLoading(false);
                setMessage('Your profile info has been updated.');
                dispatch(setUserProfile(medicareAccountData));
                updateDetails(medicareAccountData);
                if (isProfilePage) {
                    var responseData = await response.json();
                    if (responseData.mbi) {
                        await toDoUpdate(null, 3);
                    }
                }
            } else {
                setMessage('There is a problem updating your profile');
                setLoading(false);
                if (response.status === 401) {
                    await loginWithRedirect();
                }
            }
            setLoading(false);
        };

        function formSubmit() {
            document.getElementById('modalSubmitButton').click();
        }

        useImperativeHandle(ref, () => ({
            formSubmit
        }));

        return (
            <>
                {from && (
                    <div className={cardHeaderClass}>
                        Medicare Health Insurance
                    </div>
                )}
                <TrustedFormScript />

                <form
                    onSubmit={handleSubmit(data => updateProfile(data))}
                    className={styles.manageAccountForm}
                >
                    <div className={styles.infoCardDetails}>
                        {loading && <PageSpinner />}

                        {message.length > 0 && !isDirty ? (
                            <FeedbackCard
                                severity="success"
                                text={message}
                                onClose={() => setMessage('')}
                            />
                        ) : null}

                        <div>
                            {!from && (
                                <Text
                                    className={styles.medicareNameLabel}
                                    text="Name (as shown on Medicare ID card)"
                                />
                            )}

                            <div className={styles.namesBlock}>
                                <FirstNameTextField
                                    autoFocus
                                    defaultValue={firstName}
                                    error={errors.firstName}
                                    id="firstName"
                                    register={register}
                                    labelText={from && 'First Name'}
                                    labelClassName={datePickerLabelClass}
                                    noPlaceholder={noPlaceholder}
                                    required
                                />

                                <LastNameTextField
                                    defaultValue={lastName}
                                    error={errors.lastName}
                                    id="lastName"
                                    register={register}
                                    required
                                    labelText={from && 'Last Name'}
                                    labelClassName={datePickerLabelClass}
                                    noPlaceholder={noPlaceholder}
                                />
                            </div>
                        </div>

                        <div className={styles.divider} />

                        <MedicareNumberTextField
                            className={styles.mcTextField}
                            defaultValue={medicareIdNumber}
                            error={errors.medicareIdNumber}
                            id="medicareIdNumber"
                            labelText={`Medicare ${from ? 'Number *' : 'ID'}`}
                            register={register}
                            required
                            labelClassName={datePickerLabelClass}
                            placeholderText={medicareNumberPlaceholderText}
                            keyDown={() => clearErrors('medicareIdNumber')}
                        />

                        <div className={styles.divider} />

                        <div className={styles.dateBlock}>
                            <DatePickerWithLabel
                                defaultValue={partA}
                                id="medicarePartADate"
                                labelText={`Part A Coverage Starts ${
                                    from ? '*' : ''
                                }`}
                                onChange={date => {
                                    setValue('medicarePartADate', date);
                                    setPartA(date);
                                    partAVal(
                                        date
                                            ? moment(date).format('MM-DD-YYYY')
                                            : ''
                                    );
                                }}
                                labelClass={datePickerLabelClass}
                                placeholder={datePlaceHolder}
                            />

                            <DatePickerWithLabel
                                defaultValue={partB}
                                id="medicarePartBDate"
                                labelText={`Part B Coverage Starts ${
                                    !from ? '*' : ''
                                }`}
                                onChange={date => {
                                    setValue('medicarePartBDate', date);
                                    setPartB(date);
                                    partBVal(
                                        date
                                            ? moment(date).format('MM-DD-YYYY')
                                            : ''
                                    );
                                }}
                                labelClass={datePickerLabelClass}
                                placeholder={datePlaceHolder}
                            />
                        </div>
                    </div>

                    <div className={footerBlock}>
                        <Text
                            text={from ? '* Required fields.' : '* optional'}
                            className={styles.optionalLabel}
                        />
                        {!from ? (
                            <div className={styles.buttonsBlock}>
                                <Button
                                    className={styles.cancelButton}
                                    label="Cancel"
                                    onClick={onCancel}
                                />
                                <Button
                                    className={`${styles.submitButton} ${
                                        isDisable && styles.disable
                                    }`}
                                    label="Submit"
                                    type="submit"
                                />
                            </div>
                        ) : (
                            <Button
                                className={styles.modalSubmitButton}
                                id="modalSubmitButton"
                                label="Submit"
                                type="submit"
                            />
                        )}
                    </div>
                </form>
            </>
        );
    }
);
export default ManageMedicareForm;
