import { allTags } from '../LearningCenterSearchFilter/data';
import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import Spinner from 'components/Spinner';
import YourHealthConditionContent from 'components/YourHealthConditionContent';
import YourHealthInfoModalContent from 'components/YourHealthInfoModalContent';

import NotFoundError from 'pages/NotFoundError';
import Profile from 'pages/Profile';

import {
    APPLICATION_SUBMITTED,
    AVAILABLE_AGENTS_ROUTE,
    CCPA_ROUTE,
    COMPARE_PLANS,
    COMPARE_PLANS_TOKEN,
    CONFIRM_EMAIL,
    CONTACT_AGENT_ROUTE,
    CONTACT_AN_AGENT_ROUTE,
    CONTACT_US_ROUTE,
    CREATE_ACCOUNT_ROUTE,
    CUSTOMER_PLAN_SNAPSHOT,
    ENROLLMENT_CHECKOUT_ROUTE,
    FINAL_EXPENSE_CONTACT_DETAILS_ROUTE,
    FINAL_EXPENSE_AGENT,
    FINAL_EXPENSE_HOME_ROUTE,
    FINAL_EXPENSE_PLANS_ROUTE,
    LEARNING_CENTER_ROUTE,
    MAINTENANCE_ROUTE,
    MANAGE_ACCOUNT_ROUTE,
    MOBILE_TERMS,
    OUR_COMMITMENT_ROUTE,
    PLANS_ROUTE,
    PLAN_ROUTE,
    PRIVACY_NOTICE_ROUTE,
    REQUEST_CALL_BACK,
    REQUEST_CALL_BACK_FORM,
    SOA_CONFIRM,
    SOA_CONFIRM_PAGE,
    TERMS_ROUTE,
    THANK_YOU_ROUTE,
    VERIFY_EMAIL_ROUTE,
    WELCOME_ROUTE,
    FINAL_EXPENSE_COVERAGE_OPTIONS_ROUTE,
    FINAL_EXPENSE_APPLICATION_ROUTE,
    FINAL_EXPENSE_BASICS_ROUTE,
    FINAL_EXPENSE_THANK_YOU_ROUTE,
    FINAL_EXPENSE_HEALTH_CONDITION_ROUTE,
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    PROFILE_ROUTE,
    SETTINGS_ROUTE,
    QUICK_PROFILE_ROUTE,
    CLIENT_SSO,
    CONTACT_AN_AGENT_NOINDEX_ROUTE,
    IUL_REQUEST_CALL_BACK,
    AGENT_BIO_ROUTE
} from 'utilities/routes';

import styles from './styles.module.scss';
const IULRequestCallback = lazy(() => import('pages/IULRequestCallback'));

const AgentProfile = lazy(() => import('pages/AgentProfile'));

const ComparePlanCodePage = lazy(() => import('pages/ComparePlanCodePage'));

const SOAConfirmation = lazy(() => import('pages/SOAConfirmation'));
const SOAConfirmationPage = lazy(
    () => import('pages/SOAConfirmation/SOAConfirmationPage')
);

const ReduxExample = lazy(() => import('components/ReduxExample'));

const TempReduxExample = lazy(() => import('temp/components/ReduxExample'));
const PlanDetailsPage = lazy(() => import('temp/pages/PlanDetails'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const AvailableAgents = lazy(() => import('pages/AvailableAgents'));
const ApplicationSubmitted = lazy(() => import('pages/ApplicationSubmitted'));
const CCPA = lazy(() => import('pages/CCPA'));
const ComparePlans = lazy(() => import('pages/ComparePlans'));
const ConfirmEmail = lazy(() => import('pages/ConfirmEmail'));
const ContactAgent = lazy(() => import('pages/ContactAgent'));
const ContactAnAgent = lazy(() => import('pages/ContactAnAgent'));
const ContactUs = lazy(() => import('pages/ContactUs'));
const EnrollmentCheckoutPage = lazy(() => import('pages/ContinueCheckout'));
const CreateAccount = lazy(() => import('pages/CreateAccount'));
const FinalExpense = lazy(() => import('pages/FinalExpense'));
const HomeFinalExpense = lazy(() => import('pages/HomeFinalExpense'));
const IndividualResource = lazy(() => import('pages/IndividualResource'));
const InternalServerError = lazy(() => import('pages/InternalServerError'));
const LearningCenter = lazy(() => import('pages/LearningCenter'));
const ManageAccount = lazy(() => import('pages/ManageAccount'));
const Medicare = lazy(() => import('pages/Medicare'));
const FinalExpenseIntroduction = lazy(
    () => import('pages/FinalExpenseIntroduction')
);
const MobileTerms = lazy(() => import('pages/MobileTerms'));
const NeedHelp = lazy(() => import('pages/NeedHelp'));
const Plans = lazy(() => import('pages/Plans'));
const PrivacyNotice = lazy(() => import('pages/PrivacyNotice'));
const RequestCallBack = lazy(() => import('pages/RequestCallBack'));
const RequestCallBackForm = lazy(() => import('pages/RequestCallBackForm'));
const SiteMaintenance = lazy(() => import('pages/SiteMaintenance'));
const TermsOfService = lazy(() => import('pages/TermsOfService'));
const ThankYou = lazy(() => import('pages/ThankYou'));
const QuickProfile = lazy(() => import('pages/QuickProfile'));
const VerifyEmail = lazy(() => import('pages/VerifyEmail'));
const Welcome = lazy(() => import('pages/Welcome'));
const Settings = lazy(() => import('pages/Settings'));
const MedicareSSORedirect = lazy(() => import('auth/MedicareSSORedirect'));

const loading = <Spinner />;

const Content = () => {
    const swapLearningCenterComponent = props => {
        const { match } = props;
        const article = match?.params?.articleName;
        const tagName = `${article}`.replaceAll('-', ' ');

        return allTags.find(
            tag => tag?.toLowerCase() === tagName?.toLowerCase()
        ) ? (
            <LearningCenter {...props} />
        ) : (
            <IndividualResource {...props} />
        );
    };

    return (
        <main className={styles.content}>
            <Suspense fallback={loading}>
                <Switch>
                    <Route exact path="/" component={HomeFinalExpense} />
                    <Route
                        exact
                        path={AVAILABLE_AGENTS_ROUTE}
                        component={AvailableAgents}
                    />
                    <Route exact path={WELCOME_ROUTE} component={Welcome} />
                    <Route
                        exact
                        path={CONTACT_US_ROUTE}
                        component={ContactUs}
                    />
                    <Route
                        exact
                        path={CONTACT_AGENT_ROUTE}
                        component={ContactAgent}
                    />
                    <Route
                        exact
                        path={CONTACT_AN_AGENT_NOINDEX_ROUTE}
                        component={ContactAnAgent}
                    />
                    <Route
                        exact
                        path={SOA_CONFIRM}
                        component={SOAConfirmation}
                    />
                    <Route
                        exact
                        path={SOA_CONFIRM_PAGE}
                        component={SOAConfirmationPage}
                    />
                    <Route
                        exact
                        path={COMPARE_PLANS_TOKEN}
                        component={ComparePlanCodePage}
                    />

                    <Route
                        exact
                        path={CUSTOMER_PLAN_SNAPSHOT}
                        component={ComparePlanCodePage}
                    />
                    <Route
                        exact
                        path={PRIVACY_NOTICE_ROUTE}
                        component={PrivacyNotice}
                    />
                    <Route exact path={CCPA_ROUTE} component={CCPA} />
                    <Route
                        exact
                        path={LEARNING_CENTER_ROUTE}
                        component={LearningCenter}
                    />
                    <Route
                        exact
                        path={TERMS_ROUTE}
                        component={TermsOfService}
                    />
                    <Route exact path={MOBILE_TERMS} component={MobileTerms} />
                    <Route
                        exact
                        path={CONTACT_AN_AGENT_ROUTE}
                        component={NeedHelp}
                    />
                    <Route
                        exact
                        path={REQUEST_CALL_BACK}
                        component={RequestCallBack}
                    />
                    <Route
                        exact
                        path={IUL_REQUEST_CALL_BACK}
                        component={IULRequestCallback}
                    />
                    <Route
                        exact
                        path={FINAL_EXPENSE_REQUEST_CALL_BACK}
                        component={RequestCallBack}
                    />
                    <Route
                        exact
                        path={FINAL_EXPENSE_AGENT}
                        component={NeedHelp}
                    />
                    <Route
                        exact
                        path={REQUEST_CALL_BACK_FORM}
                        component={RequestCallBackForm}
                    />
                    <Route exact path={PLANS_ROUTE} component={Plans} />
                    <Route
                        exact
                        path={FINAL_EXPENSE_HOME_ROUTE}
                        component={HomeFinalExpense}
                    />
                    <Route
                        exact
                        path={FINAL_EXPENSE_CONTACT_DETAILS_ROUTE}
                        component={YourHealthInfoModalContent}
                    />
                    <Route
                        exact
                        path={FINAL_EXPENSE_HEALTH_CONDITION_ROUTE}
                        component={YourHealthConditionContent}
                    />
                    <Route
                        exact
                        path={FINAL_EXPENSE_PLANS_ROUTE}
                        component={FinalExpense}
                    />
                    <Route
                        exact
                        path={PLAN_ROUTE}
                        component={PlanDetailsPage}
                    />
                    <Route
                        exact
                        path={FINAL_EXPENSE_BASICS_ROUTE}
                        component={FinalExpenseIntroduction}
                    />
                    <Route
                        exact
                        path={FINAL_EXPENSE_COVERAGE_OPTIONS_ROUTE}
                        component={FinalExpenseIntroduction}
                    />
                    <Route
                        exact
                        path={CLIENT_SSO}
                        component={MedicareSSORedirect}
                    />
                    <Route
                        exact
                        path={FINAL_EXPENSE_APPLICATION_ROUTE}
                        component={FinalExpenseIntroduction}
                    />
                    <Route
                        exact
                        path="/guide/final-expense-basics"
                        component={FinalExpenseIntroduction}
                    />
                    <Route exact path="/guide/:id" component={Medicare} />

                    <Route
                        exact
                        path="/learning-center/:articleName"
                        component={props => swapLearningCenterComponent(props)}
                    />
                    <Route exact path={THANK_YOU_ROUTE} component={ThankYou} />
                    <Route
                        exact
                        path={FINAL_EXPENSE_THANK_YOU_ROUTE}
                        component={ThankYou}
                    />
                    <Route
                        exact
                        path={QUICK_PROFILE_ROUTE}
                        component={QuickProfile}
                    />

                    <Route
                        exact
                        path={AGENT_BIO_ROUTE}
                        component={AgentProfile}
                    />

                    <Route
                        exact
                        path={MAINTENANCE_ROUTE}
                        component={SiteMaintenance}
                    />

                    <Route
                        exact
                        path="/reduxExample"
                        component={ReduxExample}
                    />
                    <Route
                        exact
                        path="/temp/reduxExample"
                        component={TempReduxExample}
                    />
                    <Route
                        exact
                        path={COMPARE_PLANS}
                        component={ComparePlans}
                    />
                    <Route
                        exact
                        path={OUR_COMMITMENT_ROUTE}
                        component={AboutUs}
                    />
                    <Route
                        exact
                        path={CREATE_ACCOUNT_ROUTE}
                        component={CreateAccount}
                    />
                    <Route
                        exact
                        path={MANAGE_ACCOUNT_ROUTE}
                        component={ManageAccount}
                    />
                    <Route
                        exact
                        path={VERIFY_EMAIL_ROUTE}
                        component={VerifyEmail}
                    />
                    <Route
                        exact
                        path={CONFIRM_EMAIL}
                        component={ConfirmEmail}
                    />
                    <Route
                        exact
                        path={ENROLLMENT_CHECKOUT_ROUTE}
                        component={EnrollmentCheckoutPage}
                    />
                    <Route exact path={PROFILE_ROUTE} component={Profile} />
                    <Route exact path={SETTINGS_ROUTE} component={Settings} />
                    <Route
                        exact
                        path={APPLICATION_SUBMITTED}
                        component={ApplicationSubmitted}
                    />
                    <Route exact path="/500" component={InternalServerError} />

                    <Route path="*">
                        <NotFoundError />
                    </Route>
                </Switch>
            </Suspense>
        </main>
    );
};

export default Content;
