import { useState } from 'react';

import Heading5 from 'components/Heading5';
import PasswordRuleList from 'components/PasswordRuleList';
import TextFieldWithLabel from 'components/TextFieldWithLabel';

import {
    invalidPasswordMessage,
    passwordRegex,
    passwordRequiredMessage
} from './data';

import styles from './styles.module.scss';
import {
    faViewHide,
    faViewShow
} from '@awesome.me/kit-cc709582e6/icons/kit/custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PasswordTextFieldWithLabel = ({
    autoFocus,
    defaultValue,
    error,
    id,
    labelText,
    register,
    passwordVisibilityClass
}) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(value => !value);
    };

    return (
        <div className={styles.container}>
            <span
                onClick={togglePasswordVisibility}
                className={`${styles.passwordVisibility} ${passwordVisibilityClass}`}
            >
                <FontAwesomeIcon
                    aria-label={'lineIcon'}
                    className={styles.icon}
                    color={'#1157ee'}
                    icon={isPasswordVisible ? faViewHide : faViewShow}
                    id="passwordVisibleIcon"
                />

                <Heading5
                    className={styles.heading5}
                    text={isPasswordVisible ? 'Hide' : 'Show'}
                />
            </span>

            <TextFieldWithLabel
                autoFocus={autoFocus}
                className={styles.textFieldWithLabel}
                defaultValue={defaultValue}
                error={error}
                errorMessage={passwordRequiredMessage}
                errorMessagePattern={invalidPasswordMessage}
                errorTextClassName={styles.errorText}
                fieldClassName={styles.textField}
                id={id}
                labelClassName={styles.label}
                labelText={labelText}
                pattern={passwordRegex}
                placeholder="Password"
                register={register}
                required
                type={isPasswordVisible ? 'text' : 'password'}
            />
            {error && error?.type !== 'doubleConfirm' && (
                <div className={styles.passwordRuleContainer}>
                    <PasswordRuleList />
                </div>
            )}
        </div>
    );
};

export default PasswordTextFieldWithLabel;
