import Icon from 'components/Icon';

import image from './image.svg';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMenu } from '@awesome.me/kit-cc709582e6/icons/kit/custom';

const LineIcon = () => {
    const icon = <Icon alt="lineIcon" className={styles.icon} image={image} />;
    return (
        <div className={styles.lineIcon} id="lineIcon">
            <FontAwesomeIcon
                aria-label={'lineIcon'}
                className={styles.icon}
                color={'#1157EE'}
                icon={faMenu}
                size={'xl'}
            />
        </div>
    );
};

export default LineIcon;
