import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Popover } from '@mui/material';

import Button from 'components/Button';
import {
    setSelectedQuickProfileItem,
    setTargetPage
} from 'components/QuickProfileHealthModal/slice';
import SignInListItems from 'components/SignInListItems';
import useFetch from 'components/hooks/useFetch';
import LineIcon from 'components/icons/LineIcon';
import UserInfoIcon from 'components/icons/UserInfoIcon';

import { PROFILE_IMAGE_SERVICE_URL } from 'utilities/env';
import { CREATE_ACCOUNT_ROUTE, SETTINGS_ROUTE } from 'utilities/routes';
import scrollToTop from 'utilities/scrollToTop';

import { setProfileImage } from './slice';

import styles from './styles.module.scss';

const avatarStyles = {
    bgcolor: 'blue',
    border: '2px solid white',
    fontSize: '12px',
    height: '48px',
    textTransform: 'uppercase',
    width: '48px'
};

const UserProfile = ({
    handleLoginClick,
    isAuthenticated,
    onItemClick,
    onLogout
}) => {
    const dispatch = useDispatch();
    const [anchorElement, setAnchorElement] = useState(null);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const profileImage = useSelector(state => state.profileImage.profileImage);
    const { loginWithRedirect } = useAuth0();
    const open = Boolean(anchorElement);
    const { consumerId } = userProfile;
    const { Get: getProfileImage } = useFetch(
        `${PROFILE_IMAGE_SERVICE_URL}/${consumerId}`
    );
    const id = open ? 'simple-popover' : '';

    const history = useHistory();

    const anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right'
    };

    const transformOrigin = {
        vertical: 'top',
        horizontal: 'right'
    };

    const handleClick = event => {
        setAnchorElement(val => (val ? null : event.currentTarget));
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const manageAccount = () => {
        onItemClick();
        handleClose();
        history.push(SETTINGS_ROUTE);
    };

    const handleLogout = () => {
        handleClose();
        onLogout();
    };
    const currentPage = window.location.href.substring(
        window.location.href.indexOf('#') + 1
    );
    const createAccount = () => {
        if (
            window.location.href.includes('quick-profile') &&
            currentPage !== 'personalInformation' &&
            currentPage !== 'complete' &&
            window.location.hash &&
            !isAuthenticated
        ) {
            dispatch(setTargetPage('Sign-Up'));
            dispatch(setSelectedQuickProfileItem(CREATE_ACCOUNT_ROUTE));
        } else {
            onItemClick();
            history.push(CREATE_ACCOUNT_ROUTE);
        }
    };

    const handleSignIn = async () => {
        try {
            if (
                window.location.href.includes('quick-profile') &&
                currentPage !== 'personalInformation' &&
                currentPage !== 'complete' &&
                window.location.hash &&
                !isAuthenticated
            ) {
                dispatch(setTargetPage('Login'));
                dispatch(setSelectedQuickProfileItem('Login'));
            } else {
                await loginWithRedirect();
            }
            scrollToTop();
        } catch (e) {
            console.error('sign in error: ', e);
        }
    };

    const getName = useMemo(() => {
        if (!userProfile) return '';
        const { firstName, lastName } = userProfile;
        return firstName ? firstName?.charAt(0) + lastName?.charAt(0) : '';
    });

    useEffect(() => {
        const init = async () => {
            const response = await getProfileImage();
            if (response) {
                dispatch(setProfileImage(response));
            }
        };
        if (!!userProfile?.consumerId) init();
         
    }, [userProfile]);

    return (
        <>
            <div className={styles.desktop}>
                <div
                    className={`${styles.profileIcon} ${
                        open ? styles.profileIconActive : ''
                    }`}
                    onClick={handleClick}
                >
                    <LineIcon />
                    {!isAuthenticated ? (
                        <UserInfoIcon
                            active={isAuthenticated}
                            icon={UserInfoIcon}
                        />
                    ) : (
                        <Avatar alt="RZ" sx={avatarStyles} src={profileImage} id='profileImage'>
                            {getName}
                        </Avatar>
                    )}
                </div>
                <Popover
                    anchorEl={anchorElement}
                    anchorOrigin={anchorOrigin}
                    className={styles.popover}
                    elevation={3}
                    id={id}
                    onClose={handleClose}
                    open={open}
                    transformOrigin={transformOrigin}
                >
                    <div className={styles.userProfileOption}>
                        <SignInListItems
                            handleLogout={handleLogout}
                            manageAccount={manageAccount}
                            isAuthenticated={isAuthenticated}
                            handleLoginClick={handleLoginClick}
                            isMobile={false}
                            handleClose={handleClose}
                        />
                    </div>
                </Popover>
            </div>
            <div className={styles.mobile}>
                <div className={styles.buttonsContainer}>
                    {!isAuthenticated ? (
                        <>
                            <Button
                                className={styles.button}
                                label="Sign Up"
                                onClick={createAccount}
                                id={'signUpButton'}
                            />
                            <Button
                                className={styles.button}
                                label="Log In"
                                onClick={handleSignIn}
                                id={'logInButton'}
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                className={styles.settingButton}
                                label="Settings"
                                onClick={manageAccount}
                                id={'manageAccountButton'}
                            />
                            <Button
                                className={styles.button}
                                label="Logout"
                                onClick={handleLogout}
                                id={'logoutButton'}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default UserProfile;
