import { createSlice } from '@reduxjs/toolkit';

import {
    FINALEXPENSE_AGENT_DATA,
    MEDICARE_AGENT_DATA,
    SELECTED_AGENT_TYPE,
    SELECTED_PLAN_TYPE
} from 'utilities/storageKeys';

import { medicarePlanType } from './constants';

import { get, set } from 'temp/utilities/storage';

export const PlanTypeDetail = createSlice({
    name: 'PlanTypeDetail',

    initialState: {
        selectedPlanType: get(SELECTED_PLAN_TYPE, medicarePlanType),
        selectedAgentType: get(SELECTED_AGENT_TYPE, medicarePlanType),
        selectedItem: null,
        checkPreferredAgentModal: false,
        medicareAgent: null,
        finalExpenseAgent: null,
        subProductSelection: false
    },

    reducers: {
        setSelectedPlanType: (state, action) => {
            set(SELECTED_PLAN_TYPE, action.payload);
            set(SELECTED_AGENT_TYPE, action.payload);
            state.selectedPlanType = action.payload;
            state.selectedAgentType = action.payload;
            state.checkPreferredAgentModal = true;
        },
        setSelectedAgentType: (state, action) => {
            set(SELECTED_AGENT_TYPE, action.payload);
            state.selectedAgentType = action.payload;
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
        setCheckPreferredAgentModal: (state, action) => {
            state.checkPreferredAgentModal = action.payload;
        },
        setMedicareAgent: (state, action) => {
            set(MEDICARE_AGENT_DATA, action.payload);
            state.medicareAgent = action.payload;
        },
        setFinalExpenseAgent: (state, action) => {
            set(FINALEXPENSE_AGENT_DATA, action.payload);
            state.finalExpenseAgent = action.payload;
        },
        showSubProductSelection: state => {
            state.subProductSelection = true;
        },
        hideSubProductSelection: state => {
            state.subProductSelection = false;
        }
    }
});

export const {
    setSelectedPlanType,
    setSelectedItem,
    setCheckPreferredAgentModal,
    setMedicareAgent,
    setFinalExpenseAgent,
    setSelectedAgentType,
    showSubProductSelection,
    hideSubProductSelection
} = PlanTypeDetail.actions;

export default PlanTypeDetail.reducer;
