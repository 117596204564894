import { Box, IconButton, Paper, Typography } from '@mui/material';
import styles from './styles.module.scss';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Heading3 from 'components/Heading3';
import { finalExpensePlanType } from './constants';
import { set } from 'temp/utilities/storage';
import {
    COVERAGE_AMOUNT,
    FROM_SUB_PRODUCT,
    IUL_COVERAGE_AMOUNT,
    PLAN_TYPE_KEY,
    SUB_PRODUCT_TYPE_SELECTED
} from 'utilities/storageKeys';
import {
    PLAN_TYPE_FILTER_FE,
    PLAN_TYPE_FILTER_IUL
} from 'components/FinalExpensePlanTypeTabs/utility/constants';

export const subProducts = ['Indexed Universal Life', 'Final Expense'];
export const IndexUniversalLifeSubProduct = 'Indexed Universal Life';
export const FinalExpenseSubProduct = 'Final Expense';

export const SubProductType = ({
    onClose,
    onSelectPlanType,
    subProductSelection
}) => {
    const onSelect = value => {
        set(SUB_PRODUCT_TYPE_SELECTED, value);
        set(FROM_SUB_PRODUCT, true);
        set(IUL_COVERAGE_AMOUNT, '50000');
        set(COVERAGE_AMOUNT, '15000');
        if (value === IndexUniversalLifeSubProduct)
            set(PLAN_TYPE_KEY, PLAN_TYPE_FILTER_IUL);
        if (value === FinalExpenseSubProduct)
            set(PLAN_TYPE_KEY, PLAN_TYPE_FILTER_FE);
        onClose();
        onSelectPlanType(finalExpensePlanType, value);
    };
    const SubProduct = ({ name }) => {
        return (
            <Paper
                elevation={1}
                className={styles.subProduct}
                onClick={() => onSelect(name)}
            >
                <Typography className={styles.subProductText}>
                    {name}
                </Typography>
            </Paper>
        );
    };
    return (
        <Box className={styles.subProductContainer} id="fePlanType">
            {!subProductSelection && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    width="100%"
                    onClick={() =>
                        document
                            .getElementById('planTypeContent')
                            ?.scrollIntoView({ behavior: 'smooth' })
                    }
                >
                    <IconButton onClick={onClose}>
                        <ExpandLessIcon
                            className={styles.collapseIcon}
                            fontSize="large"
                        />
                    </IconButton>
                </Box>
            )}
            <Heading3
                className={styles.subProductTypeHeading}
                text="What Type of Life Products?"
            />
            {subProducts.map((name, i) => (
                <SubProduct name={name} key={i} />
            ))}
        </Box>
    );
};
