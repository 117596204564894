import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import ContactAgentButton from 'components/ContactAgentButton';
import PlansCtaTitle from 'components/PlansCtaTitle';

import purlAgentContext from 'contexts/purlAgentContext';

import getRequestCallBackUrl from 'utilities/getRequestCallBackUrl';
import {
    FINAL_EXPENSE_PLANS_ROUTE,
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    IUL_REQUEST_CALL_BACK,
    REQUEST_CALL_BACK
} from 'utilities/routes';
import { CONTACT_CALLBACK_KEY } from 'utilities/storageKeys';

import styles from './styles.module.scss';

import { set } from 'temp/utilities/storage';

const PlansCta = ({ dataGtm = '', finalExpensePlanType }) => {
    const { purlAgentData } = useContext(purlAgentContext);
    const history = useHistory();
    const [agentInfo, setAgentInfo] = useState(null);
    const { isAuthenticated } = useAuth0();

    const showRequestCallBackLink = () => {
        const url = getRequestCallBackUrl(false);

        history.push(url);
    };
    const medicareAgent = useSelector(
        state => state.planTypeDetail.medicareAgent
    );
    const finalExpenseAgent = useSelector(
        state => state.planTypeDetail.finalExpenseAgent
    );
    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );

    const PLAN_TYPE_FILTER_IUL = 6;
    useEffect(() => {
        if (medicareAgent || finalExpenseAgent) {
            if (selectedPlanType === 'Medicare') setAgentInfo(medicareAgent);
            else setAgentInfo(finalExpenseAgent);
        }
    }, [isAuthenticated, purlAgentData]);

    const onClickContactUs = () => {
        if (window.location.pathname === FINAL_EXPENSE_PLANS_ROUTE) {
            if (purlAgentData && purlAgentData?.isFinalExpenseAgent) {
                showRequestCallBackLink();
            } else {
                set(CONTACT_CALLBACK_KEY, 'true');
                history.push(
                    finalExpensePlanType &&
                        finalExpensePlanType === PLAN_TYPE_FILTER_IUL
                        ? IUL_REQUEST_CALL_BACK
                        : FINAL_EXPENSE_REQUEST_CALL_BACK
                );
            }
        } else {
            if (purlAgentData && purlAgentData?.isMedicareAgent) {
                showRequestCallBackLink();
            } else {
                set(CONTACT_CALLBACK_KEY, 'true');
                history.push(REQUEST_CALL_BACK);
            }
        }
    };

    return (
        <div className={styles.plansCta} data-gtm={dataGtm}>
            <div className={styles.content}>
                <PlansCtaTitle finalExpensePlanType={finalExpensePlanType} />

                <ContactAgentButton
                    onClick={onClickContactUs}
                    className={styles.plansCtaBtn}
                    label={`Contact ${agentInfo?.agentFirstName ?? 'an Agent'}`}
                />
            </div>
        </div>
    );
};

export default PlansCta;
