const env = import.meta.env;

const CONFIG = Object.keys(env)
    .filter(entry => /^VITE_APP_/.test(entry))
    .reduce((accumulator, entry) => {
        accumulator[entry.replace(/^VITE_APP_/, '')] = env[entry];

        return accumulator;
    }, {});

// new environment variables must be added below without the VITE_APP_ prefix
export const {
    AGENT_API_BASE_URL,
    API_BASE_URL,
    API_BASE_URL_V2,
    API_ENROLL_URL,
    APP_BASE_URL,
    AVAILABLE_AGENT_TOKEN,
    AVAILABLE_AGENT_URL,
    BRITE_VERIFY_APIKEY,
    BRITE_VERIFY_URL,
    BUILD_ENV,
    CONSUMER_BASE_URL,
    CONSUMER_SERVICE_URL,
    HEALTH_CONDITION__URL,
    CONTACT_LEAD_FORM_IFRAME_URL,
    ENROLLMENT_API_BASE_URL,
    JAN_2023_TOGGLE,
    QUOTE_URL,
    SANITY_CLIENT_PROJECT_ENVIRONMENT,
    SANITY_CLIENT_PROJECT_ID,
    SENTRY_DSN,
    LEADS_URL,
    PROFILE_IMAGE_SERVICE_URL,
    IUL_TOGGLE
} = CONFIG;
