import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Body2 from 'components/Body2';
import Button from 'components/Button';
import { INFO_COVERAGE_TYPES_MODAL_DESCRIPTION } from 'components/FinalExpenseFilterContainer';
import Heading3 from 'components/Heading3';
import InfoModal from 'components/InfoModal';
import PrescreenAvailable from 'components/PrescreenAvailable';

import {
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    IUL_REQUEST_CALL_BACK
} from 'utilities/routes';

import styles from './styles.module.scss';

const PLAN_TYPE_FILTER_IUL = 6;
const CoverageDetailHeading = ({ title, body, bodySubtext }) => {
    return (
        <div
            className={`${styles.coverageDetailHeading} ${
                title === 'Coverage Amount' && styles.coverageAmountHeading
            } `}
        >
            <Body2 text={title} className={styles.coverageDetailTitle} />
            <div className={styles.coverageDetailBodyContainer}>
                <Heading3 text={body} className={styles.coverageDetailBody} />
                {bodySubtext && (
                    <Body2
                        text={bodySubtext}
                        className={styles.coverageDetailBodySubtext}
                    />
                )}
            </div>
        </div>
    );
};

const FinalExpensePlanCard = ({ planData, finalExpensePlanType }) => {
    const history = useHistory();
    const finalExpenseAgent = useSelector(
        state => state.planTypeDetail.finalExpenseAgent
    );

    const handleContactAgent = () => {
        history.push(
            finalExpensePlanType === PLAN_TYPE_FILTER_IUL
                ? IUL_REQUEST_CALL_BACK
                : FINAL_EXPENSE_REQUEST_CALL_BACK
        );
    };

    return (
        <div className={styles.finalExpensePlanCard}>
            <div className={styles.planHeader}>
                <div className={styles.planNameContent}>
                    <div className={styles.planName}>{planData.planName}</div>
                    <div className={styles.planNameSubtext}>
                        Coverage Type:&nbsp;<b>{planData.coverageType}</b>
                        {planData.coverageType !== 'Simplified IUL' && (
                            <InfoModal
                                className="filter-section-info-modal"
                                iconClassName={'filter-section-info-icon'}
                                description={
                                    INFO_COVERAGE_TYPES_MODAL_DESCRIPTION
                                }
                                modalBoxClassName={'finalPlanFilterModal'}
                                hideFooter={true}
                                headerClassName={'finalPlanFilterModalHeader'}
                                title={'Coverage Types'}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.planLogo}>
                    <img src={planData.planImage} alt={planData.planName} />
                </div>
            </div>
            <div className={styles.planBody}>
                <div className={styles.coverageSection}>
                    <CoverageDetailHeading
                        title="Coverage Amount"
                        body={`$${planData.coverageAmount.toLocaleString()}`}
                    />
                    <CoverageDetailHeading
                        title="Monthly Premium"
                        body={`$${planData.premiumMonthly.toFixed(2)}`}
                        bodySubtext="/mo"
                    />
                </div>
                {planData.planInfo?.length > 1 && (
                    <div className={styles.policySection}>
                        <div className={styles.planDetailHeader}>
                            <Body2
                                text="Product Information"
                                className={styles.planDetailBodyHeader}
                            />
                        </div>

                        <div className={styles.planDetailTable}>
                            {planData.planInfo?.length > 1 && (
                                <>
                                    <div className={styles.planInfoTableHeader}>
                                        <div
                                            className={
                                                styles.planInfoTableHeaderColumn1
                                            }
                                        >
                                            <Body2
                                                text="Years"
                                                className={
                                                    styles.planDetailBodyHeader
                                                }
                                            />
                                        </div>
                                        <div>
                                            <Body2
                                                text="% Face Value"
                                                className={
                                                    styles.planDetailBodyHeader
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {planData.planInfo?.length > 1 &&
                                planData.planInfo
                                    .slice(1)
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            className={styles.planInfoTableRow}
                                        >
                                            <div
                                                className={
                                                    styles.planInfoTableColumn1
                                                }
                                            >
                                                <Body2 text={item[0]} />
                                            </div>
                                            <div
                                                className={
                                                    styles.planInfoTableColumn2
                                                }
                                            >
                                                <Body2 text={item[1]} />
                                            </div>
                                        </div>
                                    ))}
                        </div>
                    </div>
                )}
                <PrescreenAvailable
                    eligibility={planData.eligibility}
                    conditionList={planData.conditionList}
                />
            </div>
            <div className={styles.planFooter}>
                <Button
                    label={`Contact ${
                        finalExpenseAgent?.agentFirstName ?? 'an Agent'
                    } `}
                    className={styles.footerAgentButton}
                    onClick={handleContactAgent}
                />
            </div>
        </div>
    );
};

export default FinalExpensePlanCard;
