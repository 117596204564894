import moment from 'moment';

import {
    finalExpensePlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';

import formatPhoneNumber from './formatPhoneNumber';
import {
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    IUL_REQUEST_CALL_BACK,
    REQUEST_CALL_BACK
} from './routes';
import { PLAN_TYPE_KEY, SELECTED_PLAN_TYPE } from './storageKeys';

import { get } from 'temp/utilities/storage';
import { PLAN_TYPE_FILTER_IUL } from 'components/FinalExpensePlanTypeTabs/utility/constants';

export const getRequestCallBackUrl = (
    checkWorkingHours = false,
    phoneNumber,
    isFromRequestCallBack = false
) => {
    const selectedPlanType = get(SELECTED_PLAN_TYPE, medicarePlanType);
    const fePlanTypeFilterValue = get(PLAN_TYPE_KEY, PLAN_TYPE_FILTER_IUL);
    if (!checkWorkingHours) {
        return selectedPlanType === finalExpensePlanType
            ? fePlanTypeFilterValue === PLAN_TYPE_FILTER_IUL
                ? IUL_REQUEST_CALL_BACK
                : FINAL_EXPENSE_REQUEST_CALL_BACK
            : REQUEST_CALL_BACK;
    }

    const format = 'hh:mm:ss';

    const isWorkingHours =
        moment().isBetween(
            moment('08:00:00', format),
            moment('18:00:00', format)
        ) ||
        moment().isSame(moment('08:00:00', format)) ||
        moment().isSame(moment('18:00:00', format));

    if (phoneNumber && isWorkingHours) {
        return `tel:+${
            isFromRequestCallBack ? phoneNumber : formatPhoneNumber(phoneNumber)
        }`;
    }

    const url = isWorkingHours
        ? selectedPlanType === finalExpensePlanType
            ? FINAL_EXPENSE_REQUEST_CALL_BACK
            : REQUEST_CALL_BACK
        : '/request-call-back-form';

    return url;
};

export default getRequestCallBackUrl;
